.blogPost {
  background-color: #222a35;
  color: #fff;
}
.featureImage {
  text-align: center;
}
.blogPost_content {
  padding-bottom: 40px;
}
.blogPost_content img {
  max-width: 100%;
  height: auto;
}
.blogPost_content h1 {
  font-size: 36px;
  margin: 20px 0px;
}
.blogPost_content h2 {
  font-size: 30px;
  margin: 20px 0px;
}
.blogPost_content h3 {
  font-size: 25px;
  margin: 20px 0px;
}
.blogPost_content p {
  margin: 10px 0px;
  text-align: justify;
  line-height: 30px;
}
.blogPost_content h4{
  font-size: 20px;
  margin: 10px 0px;
}
.blogPost_content span{
  font-weight: bold;
}
/* List Comments CSS */
.listComments {
  background-color: black;
  padding: 40px 0px;
  color: #fff;
}
.listComments h2 {
  position: relative;
}
.listComments h2::after {
  content: " ";
  display: block;
  position: absolute;
  height: 4px;
  width: 50px;
  background: red;
  left: 0px;
  margin-top: 10px;
}
.commentsData{
    margin: 40px 0px;
    padding: 20px;
    border: 1px solid grey;
    box-shadow: rgba(143, 143, 143, 0.35) 0px 5px 15px;
}
.commentsData:hover{
    border: 1px solid red;
}
.name_date{
    display: flex;
    justify-content: space-between;
}
.name_date .date{
    margin: 10px 0px;
    font-size: 20px;
}
.commentsData h3, .commentsData p{
    margin: 10px 0px;
}
.commentsData h3{
    font-size: 25px;
}
.commentsData span{
    text-align: right;
}
.btns{
    display: flex;
    align-items: left;
    margin: 20px 0px;
    gap: 10px;
}
.btn{
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
}
.replyBtn{
  font-size: 18px;
  color: white;
  font-weight: bold;
}
.like, .dislike{
    display: flex;
    gap: 10px;
    color: #fff;
    font-size: 20px;
}
.count{
    margin: -2px 0px;
}
.likedBlue{
    color: blue;
    font-size: 20px;
}
.dislikedRed{
    color: red;
    font-size: 20px;
}
/* List Comments CSS Ends */
