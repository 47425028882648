.getaQuote {
  padding: 40px;
  background-color: #2c3740;
}
.getaQuote_content {
  background-color: #41515e;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  padding: 20px;
  border-radius: 20px;
  margin: 0px 20px;
}
.input_fields {
  width: 100%;
  margin: auto;
  padding: 0px 10% 40px 10%;
}
.input_fields input,
.select_input,
.budget_input,
.getaQuoteBtn {
  width: 100%;
  background-color: #66737e;
  padding: 20px;
  border: none;
  margin: 10px 0px;
  border-radius: 20px;
}
.input_fields input::placeholder {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}
.phone1 {
  width: 100% !important;
  background-color: #66737e;
  padding: 20px 50px !important;
  border: none;
  margin: 10px 0px;
  margin-left: -10px !important;
  border-radius: 20px;
  font-weight: bold;
}
.react-tel-input .selected-flag {
  background-color: transparent !important;
  padding: 8px 10px !important;
}
.select_input,
.budget_input,
.getaQuoteBtn {
  font-weight: bold;
}

.input_fields h3 {
  padding: 20px;
  color: #fff;
}
.photography,
.videography {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 20px;
  font-weight: bold;
  color: #fff;
}
.getaQuoteBtn:hover {
  border: 2px solid red;
  color: #fff;
  cursor: pointer;
}
.error {
  color: red;
}
.green {
  color: green;
}
.pad-left {
  padding-left: 100px;
}
.getQuote_title h2 {
  color: white;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  padding: 30px 0px;
  margin-bottom: 30px;
  position: relative;
}
.getQuote_title h2::after {
  content: " ";
  display: block;
  position: absolute;
  height: 4px;
  width: 120px;
  background: red;
  left: 45%;
  margin-top: 20px;
}
/* Get A Quote Page Responsive CSS Start */
@media (max-width: 480px) {
  .getaQuote {
    padding: 40px 0px;
  }
  .input_fields {
    padding: 0px;
  }
  .getQuote_title h2::after {
    left: 27%;
  }
  .getQuote_title h2{
    font-size: 24px;
  }
  .getaQuote_content{
    margin: 0px;
  }
}
@media (min-width: 481px) and (max-width: 540px) {
  .getaQuote {
    padding: 40px 0px;
  }
  .getQuote_title h2{
    font-size: 30px;
  }
  .getaQuote_content{
    margin: 0px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .input_fields h3 {
    font-size: 16px;
  }
  .getQuote_title h2::after {
    left: 36%;
  }
  .getaQuote_content{
    margin: 0px;
  }
}
@media (min-width: 768px) and (max-width: 1130px) {
  .getaQuote {
    padding: 40px 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .getQuote_title h2::after {
    left: 42%;
  }
}
@media (min-width: 992px) and (max-width: 1040px) {
  .getQuote_title h2::after {
    left: 43%;
  }
}
@media (min-width: 1041px) and (max-width: 1199px) {
  .getQuote_title h2::after {
    left: 44%;
  }
}
/* Get A Quote Page Responsive CSS Ends */
