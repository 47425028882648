/* Why Choose Us Section CSS Start */
.why_us_section {
  background-color: #273139;
  padding: 40px 0px;
}
.why_us_p {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #fff;
  margin: 15px 0px;
  text-align: justify;
}
.why_us_cards_content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
}
.why_us_card {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  padding: 20px;
  margin: 20px 0px;
  background-color: #212a31;
  border-radius: 20px;
}
.why_us_card:hover {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  background-color: #373f45;
}
.icon_box {
  color: #d81921;
  font-size: 60px;
}
.card_title {
  font-size: 25px;
  color: white;
  margin-bottom: 20px;
}
.card_text {
  font-size: 16px;
  color: white;
  margin-bottom: 20px;
}
.card_button button {
  font-size: 20px;
  background-color: transparent;
  outline: none;
  border: 2px solid #d81921;
  border-radius: 50%;
  padding: 8px 10px;
  color: white;
  cursor: pointer;
}
.card_button button:hover {
  background-color: #d81921;
  border: 2px solid white;
}
/* Why Choose Us Section Responsive CSS Start */
@media (max-width: 499px) {
  .why_us_cards_content {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 500px) and (max-width: 1040px) {
  .why_us_cards_content {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1920px) {
  .why_us_p {
    font-size: 18px;
  }
}
/* Why Choose Us Section CSS Ends */
