.blogSection{
    padding: 40px 0px;
    background-color: #1b1212;
    color: #fff;
}
.blogSection_content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.single_blog_data{
    margin: 10px 0px;
}
.single_blog_data:hover{
    box-shadow: rgba(168, 167, 167, 0.35) 0px 5px 15px;
}
.single_blog_data img{
    max-width: 100%;
    height: auto;
    cursor: pointer;
}
.single_blog_data h2{
    margin: 10px 0px;
    cursor: pointer;
}
.single_blog_data p{
    margin: 10px 0px;
}
/* Responsive CSS */
@media(max-width: 480px){
    .blogSection_content{
        grid-template-columns: 1fr;
    }
}
@media(min-width: 481px) and (max-width: 991px){
    .blogSection_content{
        grid-template-columns: 1fr 1fr;
    }
}
@media(min-width: 992px) and (max-width: 1199px){
    .single_blog_data p {
        font-size: 14px;
    }
}