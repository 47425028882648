.banner_section {
    position: relative;
  }
  .banner_image{
    background-color: #2c3740;
  }
  .banner_image img {
    width: 100%;
    height: auto;
  }
  .banner_section_content h2 {
    position: absolute;
    bottom: 50%;
    left: 43%;
    font-size: 38px;
    margin-bottom: 10px;
    color: white;
    font-weight: bolder;
  }
  .banner_section_content h4 {
    position: absolute;
    bottom: 45%;
    left: 38%;
    color: white;
  }
  .banner_section_content h4 span a {
    color: #d81921;
    text-decoration: none;
  }
  /* Banner Section Responsive CSS Start */
  @media (max-width: 399px){
      .banner_section_content h2 {
          font-size: 18px;
          left: 35%;
      }
      .banner_section_content h4{
          bottom: 42%;
          left: 20%;
          font-size: 10px;
      }
  }
  @media (min-width: 400px) and (max-width: 480px) {
      .banner_section_content h2 {
          font-size: 25px;
          left: 35%;
      }
      .banner_section_content h4{
          bottom: 42%;
          left: 20%;
          font-size: 13px;
      }
  }
  @media (min-width: 481px) and (max-width: 600px) {
      .banner_section_content h2 {
          font-size: 30px;
      }
      .banner_section_content h4{
          font-size: 14px;
      }
  }
  @media (min-width: 481px) and (max-width: 517px) {
      .banner_section_content h2 {
          left: 35%;
      }
      .banner_section_content h4 {
          bottom: 42%;
          left: 24%;
        }
  }
  @media (min-width: 518px) and (max-width: 767px) {
      .banner_section_content h2 {
          left: 37%;
      }
      .banner_section_content h4 {
          bottom: 42%;
          left: 26%;
        }
  }
  @media (min-width: 768px) and (max-width: 991px) {
      .banner_section_content h2 {
          left: 40%;
      }
      .banner_section_content h4 {
          bottom: 44%;
          left: 32%;
        }
  }
  @media (min-width: 992px) and (max-width: 1250px) {
    .banner_section_content h4 {
      bottom: 44%;
      left: 37%;
    }
  }
  @media (min-width: 1920px) {
    .banner_section_content h4 {
      left: 39%;
    }
  }
  /* Banner Section Responsive CSS Ends */
  