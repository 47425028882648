/* Get A Quote Section CSS Start */
.get_quote_section {
    background-color: white;
    margin: 80px 0px;
  }
  .get_quote_section_content {
    display: grid;
    grid-template-columns: 10fr 2fr;
    justify-content: center;
    align-items: center;
  }
  .get_quote_section_content h2 {
    font-size: 36px;
  }
  .get_quote_section_content h2:hover {
    color: #d81921;
  }
  .get_quote_section_content button {
    border: 2px solid red;
    border-radius: 30px;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 20px;
    font-weight: bolder;
  }
  .get_quote_section_content button:hover {
    background-color: #d81921;
    color: white;
  }
  /* Get A Quote Section Responsive CSS Start */
  @media (max-width: 480px){
      .get_quote_section_content {
          grid-template-columns: 2fr 1fr;
      }
      .get_quote_section_content h2 {
          font-size: 14px;
        }
        .get_quote_section_content button {
          font-size: 10px;
        }
  }
  @media (min-width: 481px) and (max-width: 767px) {
      .get_quote_section_content {
          grid-template-columns: 5fr 2fr;
      }
      .get_quote_section_content h2 {
          font-size: 20px;
        }
        .get_quote_section_content button {
          font-size: 14px;
        }
  }
  @media (min-width: 768px) and (max-width: 991px) {
      .get_quote_section_content {
          grid-template-columns: 7fr 2fr;
      }
      .get_quote_section_content h2 {
          font-size: 26px;
        }
        .get_quote_section_content button {
          font-size: 15px;
        }
  }
  @media (min-width: 992px) and (max-width: 1040px) {
    .get_quote_section_content h2 {
      font-size: 30px;
    }
    .get_quote_section_content button {
      font-size: 18px;
    }
  }
  /* Get A Quote Section CSS Ends */
  