* {
  margin: 0;
  padding: 0;
}
.font18 {
  font-size: 18px !important;
}
.cblack {
  background-color: black !important;
}
.tColor {
  background-color: transparent !important;
}
/* Animation CSS Start */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in {
  animation: fadeIn 3s;
}
/***** Slide Right *****/
.slide-right {
  animation: 3s slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
/***** Slide Left *****/
.slide-left {
  animation: 3s slide-left;
}

@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}

/* Animation CSS Ends */
/* Hero Section CSS Start */
.herosection {
  position: relative;
}
.back_image {
  background-color: #212a31;
}
.back_image img {
  width: 100%;
  height: auto;
  filter: brightness(55%);
  position: relative;
}
.banner_text {
  position: absolute;
  bottom: 20%;
  z-index: 1;
}
.banner_text_heading {
  width: 100%;
  margin-bottom: 29px;
}
.banner_text_heading h1 {
  font-size: 60px;
  color: white;
}
.banner_text_company_name p {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 48px;
  color: #ff0000;
}
.latest_video_btn button {
  border: 2px solid #d81921;
  background-color: #212a31;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 16px 34px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 20px;
}
.latest_video_btn button:hover {
  background-color: #ff0000;
  color: black;
}
.latest_video_btn button span {
  padding: 0px 10px 0px 0px;
}

/* Responsive Banner Text */
@media (min-width: 300px) and (max-width: 402px) {
  .banner_text {
    margin-left: 10px;
  }
  .banner_text_heading h1 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .banner_text_company_name p {
    font-size: 10px;
    margin-bottom: 10px;
  }
  .latest_video_btn button {
    font-size: 8px;
    padding: 6px 10px;
    border-radius: 40px;
  }
}
@media (min-width: 403px) and (max-width: 480px) {
  .banner_text_heading h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .banner_text_company_name p {
    font-size: 10px;
    margin-bottom: 20px;
  }
  .latest_video_btn button {
    font-size: 8px;
    padding: 6px 10px;
    border-radius: 40px;
  }
}
@media (min-width: 481px) and (max-width: 518px) {
  .banner_text_heading h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .banner_text_company_name p {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .latest_video_btn button {
    font-size: 10px;
    padding: 6px 14px;
    border-radius: 40px;
  }
}
@media (min-width: 519px) and (max-width: 623px) {
  .banner_text_heading h1 {
    font-size: 27px;
    margin-bottom: 20px;
  }
  .banner_text_company_name p {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .latest_video_btn button {
    font-size: 10px;
    padding: 6px 14px;
    border-radius: 40px;
  }
}
@media (min-width: 624px) and (max-width: 767px) {
  .banner_text_heading h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .banner_text_company_name p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .latest_video_btn button {
    font-size: 10px;
    padding: 6px 14px;
    border-radius: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .banner_text_heading h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .banner_text_company_name p {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .latest_video_btn button {
    font-size: 12px;
    padding: 16px 24px;
    border-radius: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1104px) {
  .page_width {
    padding: 0px 30px;
  }
  .banner_text_heading h1 {
    font-size: 50px;
  }
}
@media (min-width: 1400px) and (max-width: 1919px) {
  .banner_text {
    bottom: 30%;
  }
}
@media (min-width: 1920px) {
  .banner_text {
    bottom: 30%;
  }
  .banner_text_heading h1 {
    font-size: 80px;
  }
  .banner_text_company_name p {
    font-size: 34px;
  }
  .latest_video_btn button {
    font-size: 16px;
    padding: 26px 44px;
    border-radius: 40px;
  }
}
/* Hero Section CSS Ends */

/* Compsny Section CSS Start */
.company_section {
  background-image: url("../../assets/images/videography.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 40px 0px;
  /*filter: brightness(55%);*/
}
.company_sec_title h2 {
  color: white;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  padding: 30px 0px;
  margin-bottom: 30px;
  position: relative;
}
.company_sec_title h2:after {
  content: " ";
  display: block;
  position: absolute;
  height: 4px;
  width: 120px;
  background: red;
  left: 45%;
  margin-top: 20px;
}
.company_para1,
.company_para2,
.company_para3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #fff;
  margin-bottom: 15px;
  text-align: justify;
}

/* Responsive Company Section CSS */

@media (max-width: 480px) {
  .company_section_content {
    width: 100%;
    margin: auto;
  }
  .company_sec_title h2 {
    font-size: 18px;
  }
  .company_sec_title h2:after {
    left: 32%;
  }
  .company_para1,
  .company_para2,
  .company_para3 {
    font-size: 12px;
  }
  .company_section_content {
    padding: 0px 10px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .company_sec_title h2 {
    font-size: 32px;
  }
  .company_sec_title h2:after {
    left: 38%;
  }
}

/* Company Section CSS Ends */

/* Portfolio Section CSS Start */
.portfolio {
  /*background-color: #0c1013;*/
  background-image: url("../../assets/images/portfolio_background.webp");
  padding: 40px 0px 60px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding: 40px 0px;
}
.portfolio_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}
.portfolio_heading {
  font-size: 36px;
  font-weight: 700;
  color: white;
  margin: 20px 0px;
}
.carousel_card_heading {
  color: white;
  text-align: center;
  font-size: 16px;
}
.portfolio_carousel {
  padding: 20px 0px;
}
.portfolio_navlinks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.portfolio_navlinks li {
  list-style: none;
}
.portfolio_nav {
  text-align: right;
}
.portfolio_navlinks li button {
  border-radius: 50px;
  background: #20212b;
  font-size: 12px;
  font-weight: 600;
  color: #e3dfdf;
  border: 2px solid #2d303d;
  padding: 11px 27px;
  margin-bottom: 0;
  transition: none !important;
  cursor: pointer;
}
.portfolio_navlinks li button:hover {
  color: #d81921;
}
.timelapse_portfolio_carousel,
.videobooth_portfolio_carousel,
.reels_portfolio_carousel,
.corporate_portfolio_carousel,
.realestate_portfolio_carousel,
.headshot_portfolio_carousel,
.product_portfolio_carousel,
.fashion_portfolio_carousel,
.food_portfolio_carousel,
.wedding_portfolio_carousel,
.automotive_portfolio_carousel,
.birthday_portfolio_carousel {
  display: none;
}
.product_image {
  margin: 10px;
}
.active_button {
  border: 2px solid #d81921 !important;
  color: white !important;
}
.active_content {
  display: block;
}
.portfolio_navlinks .arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.5px 9.5px;
  border-radius: 50px;
  background: #20212b;
  margin: 0px 10px;
  border: 2px solid #2d303d;
}
.top-bottom-margin {
  margin: 40px 0px;
}
.portfolio_navlinks .arrows .left_arrow,
.portfolio_navlinks .arrows .right_arrow {
  padding: 4.5px 9.5px;
  border-radius: 0px;
  border: none;
}
.portfolio_navlinks .arrows .left_arrow {
  border-right: 1px solid #2d303d;
}
.portfolio_navlinks .arrows .right_arrow {
  border-left: 1px solid #2d303d;
}
/* Responsive Carousel Image */
@media (max-width: 480px) {
  .carousel_img,
  .carousel_img img {
    width: 290px;
  }
}
@media (min-width: 481px) and (max-width: 571px) {
  .carousel_img,
  .carousel_img img {
    width: 210px;
  }
}
@media (min-width: 572px) and (max-width: 767px) {
  .carousel_img,
  .carousel_img img {
    width: 210px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .carousel_img,
  .carousel_img img {
    width: 303px;
  }
}
@media (min-width: 992px) and (max-width: 1040px) {
  .carousel_img,
  .carousel_img img {
    width: 270px;
  }
}
@media (min-width: 1041px) and (max-width: 1199px) {
  .carousel_img,
  .carousel_img img {
    width: 304px;
  }
}
@media (min-width: 1200px) and (max-width: 1919px) {
  .carousel_img,
  .carousel_img img {
    width: 250px;
  }
}
@media (min-width: 1920px) {
  .carousel_img,
  .carousel_img img {
    width: 320px;
  }
}
/* Responsive Portfolio Section */
@media (max-width: 480px) {
  .portfolio_navlinks {
    flex-wrap: wrap;
  }
  .portfolio_heading {
    font-size: 18px;
  }
  .portfolio_content {
    display: block;
    text-align: center;
  }
  .portfolio_navlinks li {
    margin: 2px 2px;
  }
  .portfolio {
    padding: 40px 0px 20px 0px;
  }
}
@media (min-width: 481px) and (max-width: 549px) {
  .portfolio_heading {
    font-size: 20px;
  }
  .portfolio_content {
    display: block;
    text-align: center;
  }
  .portfolio_navlinks {
    flex-wrap: wrap;
  }
  .portfolio_navlinks li {
    margin: 2px 2px;
  }
}
@media (min-width: 550px) and (max-width: 767px) {
  .portfolio_navlinks li button {
    padding: 8px 16px;
  }
  .portfolio_heading {
    font-size: 20px;
  }
  .portfolio_content {
    display: block;
    text-align: center;
  }
  .portfolio_navlinks li {
    margin: 0px 2px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .portfolio_content {
    display: block;
    text-align: center;
  }
  .portfolio_navlinks li {
    margin: 0px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1040px) {
  .portfolio_heading {
    font-size: 20px;
  }
}
@media (min-width: 1041px) and (max-width: 1199px) {
  .portfolio_heading {
    font-size: 26px;
  }
}

/* Portfolio Section CSS Ends */

/* Portfolio.jsx CSS Start */
.carousel_img h2:hover {
  color: #d81921;
  cursor: pointer;
}
/* Portfolio.jsx CSS Ends */

/* About Section CSS Start */
.about_section {
  background-color: #212a31;
  max-width: 100%;
  padding: 90px 0px;
}
.about_section_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}
.left_section_content img {
  max-width: 100%;
  margin: auto;
}
.right_section_content {
  display: block;
  position: relative;
  margin: 0px 40px;
}
.right_section_content h4 {
  padding-left: 53px;
  color: #e4e2e2;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.right_section_content h4::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  width: 40px;
  height: 2px;
  background: #d81921;
}
.right_section_content h2 {
  font-size: 36px;
  font-weight: 700;
  color: white;
  font-style: normal;
}
.right_section_content .about_para {
  text-align: justify;
  margin-bottom: 15px;
  color: white;
  font-weight: 500;
}
.about_para1,
.about_para2,
.about_para3 {
  margin: 15px 0px;
}

/* Responsive About Section */
@media (max-width: 480px) {
  .about_section_content {
    display: block;
  }
  .right_section_content {
    margin: 40px 0px 0px 0px;
  }
  .right_section_content h2 {
    font-size: 28px;
  }
  .right_section_content {
    margin: 40px 10px 0px 10px;
  }
  .right_section_content .about_para {
    font-size: 12px;
  }
  .about_section {
    padding: 30px 0px;
  }
}
@media (min-width: 481px) and (max-width: 991px) {
  .about_section_content {
    display: block;
  }
  .right_section_content {
    margin: 40px 0px 0px 0px;
  }
}
@media (min-width: 992px) and (max-width: 1040px) {
  .right_section_content .about_para {
    font-size: 12px;
  }
  .right_section_content h2 {
    font-size: 32px;
  }
}
@media (min-width: 1041px) and (max-width: 1104px) {
  .right_section_content .about_para {
    font-size: 14px;
  }
}
@media (min-width: 1920px) {
  .right_section_content .about_para {
    font-size: 26px;
  }
  .right_section_content h2 {
    font-size: 40px;
  }
  .right_section_content h4 {
    font-size: 16px;
  }
}
/* About Section CSS Ends */

/* Video Quality Section CSS Start */
.video_quality_section {
  display: none;
  background-color: black;
  padding: 80px 0 80px;
}
.video_quality_section_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.section_subtitle span {
  font-weight: bold;
}
.video_quality_content h2 {
  color: white;
  font-size: 36px;
  font-weight: 700;
}
.video_quality_content .para {
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: whitesmoke;
  margin: 20px 0px;
  text-align: justify;
}
.video_quality_content .para span {
  font-weight: 900;
  max-width: 100%;
  height: auto;
}

/* Video Quality Section Responsive CSS */

@media (max-width: 480px) {
  .video_quality_section_content {
    display: block;
  }
  .video_quality_content h2 {
    font-size: 28px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .video_quality_section_content {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .video_quality_content h2 {
    font-size: 32px;
  }
  .video_quality_content .para {
    font-size: 12px;
  }
}
@media (min-width: 992px) and (max-width: 1040px) {
  .video_quality_content h2 {
    font-size: 34px;
  }
  .video_quality_content .para {
    font-size: 13px;
  }
}
@media (min-width: 1920px) {
  .video_quality_content h2 {
    font-size: 70px;
  }
  .video_quality_content .para {
    font-size: 25px;
  }
  .video_quality_content h4 {
    font-size: 18px;
  }
}
/* Video Quality Section CSS Ends */

/* Gisec Video Section CSS Start */
.right_video_section {
  text-align: center;
}
.gisec_video_section {
  background-color: #1b1212;
  padding: 40px 0px;
}
.gisec_video_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.left_text_section h3 {
  font-size: 20px;
}
.left_text_section h3 span {
  font-weight: bolder;
}
.left_text_section h2 {
  font-weight: 700;
  font-size: 36px;
  color: white;
}
.left_text_section p {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #fff;
  margin: 15px 0px;
  text-align: justify;
}
.video_rightside {
  display: block;
}
.video_button {
  background-color: #1b1212;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.video_button img {
  width: 100%;
  margin: auto;
  transition: 0.3s;
}
.video_button img:hover {
  transform: scale(1.1);
}
.video_rightside,
.overlay,
.video_rightside1,
.overlay1 {
  width: 100vw;
  height: 100vh;
  top: 10%;
  left: 0;
  position: fixed;
}
.overlay,
.overlay1 {
  background-color: black;
  opacity: 60%;
}
.video_content,
.aim_video_content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: transparent;
  padding: 14px 28px;
  border-radius: 3px;
}
/* GISEC Video Section Responsive CSS */
@media (max-width: 480px) {
  .left_text_section h3,
  .left_text_section p {
    font-size: 12px;
  }
  .left_text_section h2{
    font-size: 18px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .left_text_section h3,
  .left_text_section p {
    font-size: 14px;
  }
  .left_text_section h2 {
    font-size: 32px;
  }
}
@media (max-width: 991px) {
  .gisec_video_content {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 992px) and (max-width: 1040px) {
  .left_text_section p {
    font-size: 12px;
  }
  .left_text_section h2 {
    font-size: 25px;
  }
  .left_text_section h3 {
    font-size: 16px;
  }
}
@media (min-width: 1041px) and (max-width: 1199px) {
  .left_text_section p {
    font-size: 14px;
  }
  .left_text_section h2 {
    font-size: 32px;
  }
  .left_text_section h3 {
    font-size: 18px;
  }
}
/* GISEC Video Section CSS Ends */
