/* Why Choose Us Section CSS Start */
.pageBanner {
  position: relative;
}
.pageBanner h2 {
  position: absolute;
  bottom: 50%;
  left: 40%;
  font-size: 38px;
  margin-bottom: 10px;
  color: white;
  font-weight: bolder;
}
.whyChooseUs_section {
  background-color: grey;
  padding: 40px 0px;
}
.section_title h3 {
  font-weight: 700;
  font-size: 36px;
  color: white;
}
.whyUs_reason {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  justify-content: center;
  align-items: center;
  background-color: #ecedef;
  padding: 10px 0px;
  border-right: 3px solid #354656;
  margin: 30px 0px;
}
.rec_tri {
  position: relative;
}
.rectangle {
  background-color: white;
  width: 95%;
  padding-bottom: 1px;
  margin: 20px 0px;
}
.triangle {
  width: 0;
  height: 0;
  position: absolute;
  right: 0px;
  top: 0px;
  border-top: 10px solid transparent;
  border-left: 20px solid white;
  border-bottom: 10px solid transparent;
  margin: 20px 0px;
}
.reason_title {
  position: absolute;
  background-color: #354656;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 20px 20px 20px 80px;
  width: 85%;
  top: 0px;
  color: white;
  margin-left: 20px;
}
.reason_no {
  text-align: center;
  font-weight: bolder;
}
.no_circle1 {
  position: absolute;
  top: 0px;
  background-color: #fff;
  border-radius: 50%;
  padding: 29px 5px;
  width: 14%;
}
.no_circle2 {
  position: absolute;
  top: 5px;
  background-color: transparent;
  border: 2px solid #354656;
  border-radius: 50%;
  padding: 13px 10px;
  text-align: center;
}
.no_circle2 h4 {
  color: #354656;
}
.whyUs_reason-text {
  padding: 10px 20px;
}
.whyUs_reason_icon {
  font-size: 30px;
  color: #354656;
  text-align: center;
}
.reason_title_green {
  background-color: #64ab07;
}
.no_circle2_green {
    border: 2px solid #64ab07;
}
.color_green{
    color: #64ab07;
}
.border_right_green{
    border-right: 3px solid #64ab07;
}
.reason_title_blue {
    background-color: #009fa9;
  }
  .no_circle2_blue {
      border: 2px solid #009fa9;
  }
  .color_blue{
      color: #009fa9;
  }
  .border_right_blue{
      border-right: 3px solid #009fa9;
  }

/* Why Choose Us Section Responsive CSS Start */
@media (max-width: 480px) {
  .whyUs_reason {
    grid-template-columns: 1fr;
  }
  .no_circle1 {
    width: 18%;
  }
  .reason_title {
    width: 80%;
  }
  .whyUs_reason-text {
    text-align: justify;
  }
  .section_subtitle h3 {
    font-size: 12px;
  }
  .section_title h3 {
    font-size: 30px;
  }
  .why_us_p {
    font-size: 12px;
  }
  .pageBanner h2 {
    font-size: 30px;
    left: 26%;
  }
}
@media (max-width: 399px) {
  .pageBanner h2 {
    font-size: 25px;
    left: 23%;
  }
}
@media (min-width: 400px) and (max-width: 480px) {
  .pageBanner h2 {
    font-size: 30px;
    left: 26%;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .pageBanner h2 {
    left: 30%;
  }
  .no_circle1 {
    width: 33%;
  }
  .reason_title {
    width: 65%;
    padding: 20px 0px 20px 30px;
    margin-left: 25px;
  }
  .reason_title h4 {
    font-size: 13px;
  }
  .rectangle {
    width: 87.5%;
  }
  .whyUs_reason-text p {
    font-size: 12px;
  }
}
@media (min-width: 481px) and (max-width: 600px) {
  .pageBanner h2 {
    font-size: 25px;
    left: 34%;
  }
}
@media (min-width: 601px) and (max-width: 767px) {
  .pageBanner h2 {
    font-size: 30px;
    left: 34%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .no_circle1 {
    width: 21%;
  }
  .reason_title {
    width: 76%;
    padding: 20px 20px 20px 60px;
    margin-left: 23px;
  }
  .pageBanner h2 {
    left: 35%;
  }
}
@media (min-width: 992px) and (max-width: 1040px) {
  .no_circle1 {
    width: 18%;
  }
  .reason_title {
    width: 80%;
  }
}
@media (min-width: 1041px) and (max-width: 1199px) {
  .no_circle1 {
    width: 15%;
  }
  .reason_title {
    width: 82%;
  }
}
@media (min-width: 992px) and (max-width: 1250px) {
  .pageBanner h2 {
    left: 39%;
  }
}
@media (min-width: 1920px) {
  .no_circle1 {
    width: 10%;
  }
  .rectangle {
    width: 96.5%;
  }
}
/* Why Choose Us Section CSS Ends */

/* Videography Process CSS Start */
.videography_process{
  background-color: grey;
  padding: 40px 0px;
}
.vertical-timeline-element-date {
  font-size: 25px !important;
  top: -5px !important;
  color: #fff;
}
.vertical-timeline-element-content p span {
  font-weight: bolder;
}
.vertical-timeline-element-content p {
  color: white;
}
.vertical-timeline-element-content{
  background-color: transparent !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.vertical-timeline-element-title {
  color: #fff !important;
}
.vertical-timeline-element-title:hover {
  color: black !important;
}
/* Videography Process CSS Ends */
