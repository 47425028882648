/* Testimonial Card CSS Start */
.testimonial_card {
  background-color: white;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
    margin: 30px 10px;
    border-radius: 10px;
    padding: 20px 0px 10px 0px;
}
.testimonial_card_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.card_image img{
    width: 30%;
    margin: auto;
    border-radius: 50%;
}
.card_data{
    display: block;
}
.stars{
    color: #ffa534;
}
.test_text{
    font-size: 14px;
    margin: 10px 0px;
    text-align: center;
    padding: 0px 10px;
}
.test_icon{
    font-size: 30px;
    text-align: center;
    margin: 10px 0px;
    color: #ffa534;
}
/* Testimonial Card CSS Ends */

/* Testimonial CSS Start */
/* Google Reviews Section CSS Start */
.google_reviews {
    background-color: #212a31;
    max-width: 100%;
    padding-bottom: 90px;
    padding: 40px 0px;
  }
  .section_subtitle {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 12px;
    letter-spacing: 1px;
    position: relative;
    color: #e4e2e2;
    padding-left: 60px;
  }
  .section_subtitle:before {
    content: " ";
    display: block;
    position: absolute;
    height: 2px;
    width: 50px;
    top: 50%;
    left: 0;
    background: red;
  }
  .section_title {
    margin-top: 0;
    display: block;
  }
  .section_title h2 {
    font-weight: 700;
    font-size: 36px;
    color: white;
  }
  .heading_section {
    padding-bottom: 3rem;
  }
  /* Rewsponsive Google Reviews Section CSS */
  @media (max-width: 480px) {
    .google_reviews_content{
      padding: 0px 22px;
    }
    .section_title h2 {
      font-size: 20px;
    }
  }
  @media (min-width: 481px) and (max-width: 518px) {
    .google_reviews_content{
      padding: 0px 20px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .section_title h2 {
      font-size: 28px;
    }
  }
  /* Google Reviews Section CSS Ends*/
/* Testimonial CSS Ends */