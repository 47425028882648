/* Header CSS Start */
.mobile_header_content {
  display: none;
}
header {
  background-color: #171d22;
  position: sticky;
  top: 0px;
  z-index: 2;
}
.header_content {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  height: 100px;
  align-items: center;
}
/* Logo CSS */
.logo img {
  width: 192px;
}
/* NavLinks CSS */
.navlinks {
  display: flex;
  justify-content: space-around;
}
.navlinks li {
  list-style: none;
}
.navlinks li a,
.whatsapp {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  line-height: 1;
  /* position: relative;
  z-index: -1;*/
  padding: 8px 10px;
  border-radius: 20px;
}
.whatsapp {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #c3c3c3;
}
.navlinks li a:hover,
.whatsapp:hover {
  color: #ff0000;
}
.what_we_do_content {
  display: none;
}
.what_we_do:hover {
  .what_we_do_content {
    display: block;
    padding-top: 30px;
    position: absolute;
    background-color: #171d22;
  }
  .what_we_do_content li:hover {
    color: #ff0000;
    border-bottom: 1px solid grey;
    background-color: #212a31;
  }
  .what_we_do_content li a {
    padding: 10px 30px;
  }
}
.close_icon {
  display: none;
}
.bars_icon {
  display: none;
  color: white;
}
/* Contact Us Button CSS */
.contact_us_btn {
  display: block;
  text-align: center;
}
.contact_btn {
  background-color: #212a31;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1;
  padding: 10px 24px;
  text-align: center;
  text-transform: uppercase;
  border: 3px solid #ff0000;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
}
.contact_btn:hover {
  background-color: #ff0000;
  color: black;
}
.contact_btn span {
  font-size: 20px;
}
.contact_btn svg {
  margin-bottom: -5px;
}

/* Header Responsive CSS */
@media (min-width: 992px) and (max-width: 1199px) {
  .header_content {
    grid-template-columns: 1fr 2fr;
  }
  .contact_us_btn {
    display: none;
  }
  .page_width {
    width: 1042px;
  }
}
@media (max-width: 991px) {
  .header_content {
    display: none;
  }
  .mobile_header_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    height: 80px;
    position: sticky;
    top: 0px;
    padding: 0px 10px;
  }
  .what_we_do_content_m {
    display: none;
  }
  .what_we_do_m:hover {
    .what_we_do_content_m {
      display: block;
    }
  }
  .logo1 {
    text-align: center;
  }
  .logo1 img {
    width: 150px;
  }
  .contact_us_btn {
    display: none;
  }
  .navbar {
    background-color: #171d22;
    position: absolute;
    height: 100vh;
    width: 200px;
    top: 0px;
    right: 0px;
    text-align: left;
    z-index: 2;
    transition: 3s;
    padding: 10px;
  }
  .navlinks {
    display: block;
  }
  .navlinks li {
    border-top: 1px solid grey;
  }
  .navlinks li a:hover {
    background-color: #212a31;
    border-radius: 0px;
  }
  .navlinks li a {
    color: white;
  }
  .tm {
    border-bottom: 1px solid grey;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .close_icon {
    display: block;
    text-align: right;
    font-size: 35px;
    font-weight: 900;
    right: 0;
    color: white;
  }
  .bars_icon {
    display: block;
    text-align: right;
    font-size: 35px;
    font-weight: 900;
    padding-right: 20px;
  }
  /***** Slide Right *****/
  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
  .slide-right {
    animation: 3s slide-right;
  }
  /***** Slide Left *****/
  .slide-left {
    animation: 3s slide-left;
  }
  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }
}
/* Header CSS Ends */

/* Footer CSS Start */
.footer_color {
  background-color: #212a31;
  padding: 80px 0px 55px;
}
.footer1,
.footer2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 80px;
  align-items: center;
}
.footer_logo img {
  width: 150px;
}
.footer_navbar,
.footer2_navbar {
  display: flex;
  justify-content: right;
  align-items: center;
}
.footer2_navbar {
  justify-content: left;
}
.footer_navbar li,
.footer2_navbar li {
  list-style: none;
}
.footer_navbar li a,
.footer2_navbar li a {
  text-decoration: none;
  display: block;
  text-transform: uppercase;
  color: #c3c3c3;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 20px;
}
.footer2_navbar li a {
  padding: 10px 10px 10px 0px;
  font-weight: 500;
  font-size: 13px;
}
.footer_navbar li a:hover,
.footer2_navbar li a:hover {
  color: #ff0000;
}
.social_icons {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 12px;
  color: grey;
}
.facebook_icon,
.insta_icon,
.vimeo_icon,
.linkedin_icon,
.youtube_icon {
  border-radius: 50px;
  padding: 10px;
  background-color: white;
  margin: 0px 5px;
  cursor: pointer;
}
.facebook_icon a,
.insta_icon a,
.vimeo_icon a,
.linkedin_icon a,
.youtube_icon a {
  color: black;
  font-weight: 700;
}

.facebook_icon a:hover,
.insta_icon a:hover,
.vimeo_icon a:hover,
.linkedin_icon a:hover,
.youtube_icon a:hover {
  color: #ff0000;
}

/* Copyright Section CSS Start */
.copyright_container {
  background-color: #171d22;
  padding: 25px 0px;
}
.copyright_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 40px;
  align-items: center;
}
.copyright_text,
.office_address {
  font-size: 13px;
  color: #bcbcbc;
}
.company_name {
  color: white;
  font-weight: 900;
  cursor: pointer;
}
/* Copyright Section CSS Ends */

/* Footer Responsive CSS */
@media (max-width: 480px) {
  .page_width {
    width: 300px;
    padding: 0px;
  }
  .footer1,
  .footer2 {
    display: block;
  }
  .footer_logo {
    text-align: center;
  }
  .footer2 {
    padding-top: 60px;
  }
  .footer_navbar li a {
    font-size: 11.5px;
    padding: 5px;
  }
  .footer2_navbar li a {
    padding: 0px;
    font-size: 10px;
    text-align: center;
  }
  .social_icons,
  .footer2_navbar,
  .footer_navbar {
    justify-content: center;
  }
  .footer2_navbar {
    gap: 10px;
  }
  .social_icons {
    margin: 20px 0px;
  }
  .copyright_text {
    text-align: center;
  }
  .copyright_content {
    display: block;
    text-align: center;
  }
  .office_address {
    padding: 20px 0px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .page_width {
    width: 470px;
  }
  .footer1,
  .footer2 {
    display: block;
  }
  .footer_logo {
    text-align: center;
  }
  .footer2 {
    padding-top: 60px;
  }
  .social_icons,
  .footer2_navbar,
  .footer_navbar {
    justify-content: center;
  }
  .social_icons {
    margin: 20px 0px;
  }
  .copyright_text {
    text-align: center;
  }
  .copyright_content {
    display: block;
    text-align: center;
  }
  .office_address {
    padding: 20px 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .page_width {
    width: 750px;
  }
  .footer1 {
    display: block;
  }
  .footer2 {
    padding-top: 60px;
  }
}
@media (min-width: 992px) and (max-width: 1040px) {
  .page_width {
    width: 920px;
  }
  .footer_navbar li a {
    font-size: 13px;
  }
}
@media (min-width: 1200px) and (max-width: 1919px) {
  .page_width {
    width: 1150px;
  }
}
@media (min-width: 1920px) {
  .page_width {
    width: 1600px;
  }
}

/* Copyright Section CSS Ends */
/* Footer CSS Ends */

/* Footer1 CSS Start */
.footer_bRed {
  background-color: #7c0303 !important;
}
.footer1_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 0px;
  justify-content: center;
}
.footer_content_text {
  font-size: 16px;
  color: white;
  padding: 10px 0px;
  font-weight: 700;
}
.footer2_content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 10px 0px;
  justify-content: center;
  gap: 20px;
}
.footer_sec2 {
  background-color: #212a31;
}
.footer_text {
  color: white;
  width: 80%;
  font-size: 13px;
}
.footer_title h4 {
  color: white;
  font-weight: 700;
  font-size: 20px;
  padding: 23px 0px;
  margin-bottom: 14px;
  position: relative;
}
.footer_title h4::after {
  content: " ";
  display: block;
  position: absolute;
  height: 4px;
  width: 40px;
  background: red;
  left: 0%;
  margin-top: 5px;
}
.footer_services {
  list-style: none;
  font-size: 13px;
}
.footer_services li {
  margin-bottom: 5px;
}
.footer_services li a:hover {
  color: #ff0000;
}
.whatsapp1 {
  font-size: 13px;
  background-color: transparent;
  color: White;
  border: none;
}
.f_icon {
  display: flex;
  gap: 10px;
  padding: 0px 0px 10px 0px;
}
.footer_icon {
  color: white;
}
.footer_icon:hover,
.footer_icon_text:hover {
  color: #ff0000;
}
.footer_icon_text {
  color: white;
  font-size: 13px;
}
.footer_copy_sec {
  background-color: #171d22;
  padding: 25px 0px;
}
.footer_copy_content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.copyright_icon {
  color: white;
  padding-left: 20%;
}
.copyright_content {
  color: white;
  font-weight: bold;
  font-size: 13px;
  padding: 0px 0px 3px 0px;
}
/* Footer1 CSS Ends */

/* Footer1 Responsive CSS Start */
@media (max-width: 480px){
  .footer_title h4::after{
    left: 43%;
  }
  .copyright_icon{
    padding-left: 0%;
  }
}
@media (min-width: 481px) and (max-width: 767px){
  .footer_title h4::after{
    left: 46%;
  }
}
@media (max-width: 767px){
  .footer2_content {
    grid-template-columns: 1fr;
  }
  .copyright_content{
    display: flex;
    align-items: center;
  }
  .footer_text{
    width: 100%;
    text-align: center;
    padding: 0px 10px;
  }
  .footer1_content{
    grid-template-columns: 1fr;
  }
  .footer_content_text{
    text-align: center;
  }
  .footer_title h4{
    text-align: center;
  }
  .footer_services{
    text-align: center;
  }
  .f_icon{
    margin: 0px 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer2_content {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 992px) and (max-width: 1110px) {
  .copyright_icon {
    padding-left: 25%;
  }
  .footer_icon_text,
  .footer_services,
  .footer_text {
    font-size: 12px;
  }
  .footer_title h4 {
    margin-bottom: 0px;
    padding: 17px 0px 27px 0px;
  }
  .footer_logo img {
    width: 125px;
  }
  .footer_text {
    width: 85%;
  }
}
/* Footer1 Responsive CSS Ends */
