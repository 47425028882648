/* Active Clients Section CSS Start */
.active_clients_section{
    background-color: #707070;
    color: white;
    padding: 20px 0px;
  }
  .active_clients_content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 60px 0px;
  }
  .active_icon{
    color: white
  }
  .active_icon span{
    border: 2px solid black;
    border-radius: 50%;
    font-size: 80px;
    padding: 40px 40px 20px 40px;
  }
  .active_icon:hover{
    color: #303030;
  }
  .active_icon span:hover{
    border: 2px solid #d81921;
  }
  .active_customers h2{
    font-size: 50px;
    margin: 30px 0px 10px 0px;
  }
  .active_customers h4{
    color: #212a31;
  }
  .active_customers h4:hover{
    color: black;
  }
  
  /* Active Clients Responsive CSS Start */
  @media (max-width: 480px){
    .active_clients_content{
      grid-template-columns: 1fr;
      gap: 50px;
    }
  }
  @media (min-width: 481px) and (max-width:991px){
    .active_clients_content{
      grid-template-columns: 1fr 1fr;
      gap: 50px;
    }
  }
  /* Active Clients Section CSS Ends */
  