.commentsform_section {
  background-color: black;
  padding: 40px 0px;
  color: #fff;
}
.commentsform_section_content h2,
.commentsform_section_content p {
  margin: 10px 0px;
}
.comment {
  width: 100%;
  height: 300px;
  margin: 20px 0px 10px 0px;
  font-size: 14px;
}
.comment textarea {
  padding: 10px 20px;
}
.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.inputs input {
  border: none;
  padding: 10px 20px;
  width: 100%;
  margin: 20px 0px;
}
.postCommentBtn {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #171d22;
  color: white;
  padding: 20px 30px;
  border-radius: 20px;
  font-weight: bold;
  border: 2px solid red;
}
.postCommentBtn:hover {
  background-color: red;
  border: 2px solid #fff;
}
.error {
  color: red;
  margin-top: 30px !important;
}
.submit {
  color: green;
}
/* Responsive CSS */
@media(max-width: 480px){
  .inputs {
    grid-template-columns: 1fr;
  }
  .inputs input {
    margin: 10px 0px;
  }
  .postCommentBtn{
    margin: 10px 0px;
  }
}
/* Reply Form CSS Start */
.replyFormShow{
  display: none;
}
.formShow{
  display: block;
}
.marginTop{
  margin-top: 30px !important;
}

