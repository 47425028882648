/* Services Section CSS Start */
.services_section {
  background-color: black;
  padding: 40px 0 50px;
}
.services_section_content {
  text-align: center;
}
.services_section_content h3 {
  color: #fff;
  text-align: left;
  font-size: 30px;
  margin-top: 30px;
}
.services_section_content h4 {
  display: block;
  line-height: 1;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  margin-bottom: 12px;
  letter-spacing: 1px;
  color: #d81921;
}
.services_section_content h2 {
  font-size: 35px;
  color: white;
  font-weight: 700;
}
.whatWeDo_text {
  padding-top: 1px;
}
.whatWeDo_text p {
  color: #fff;
  margin: 10px 0px;
  text-align: justify;
  line-height: 1.5;
}
.whatWeDo_text p span {
  font-size: 18px;
  font-weight: bolder;
}
.whatWeDo_text p a:hover {
  color: #d81921;
}
.services_content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 40px 0px;
}
.services_content img {
  width: 100%;
  margin: auto;
}
.services_content h2 {
  font-size: 16px;
  color: white;
  margin: 10px 0px;
  text-align: left;
}
.services_content h2:hover {
  color: #d81921;
}

/* Services Section Responsive CSS Start */
@media (max-width: 480px) {
  .services_section_content {
    padding: 0px 10px;
  }
  .services_section_content h2 {
    font-size: 18px;
  }
  .services_content {
    display: grid;
    grid-template-columns: 1fr;
  }
  .services_content h2 {
    font-size: 13px;
  }
  .whatWeDo_text p {
    font-size: 12px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .services_section_content {
    padding: 0px 10px;
  }
  .services_section_content h2 {
    font-size: 30px;
  }
  .services_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .services_content h2 {
    font-size: 16px;
  }
  .whatWeDo_text p {
    font-size: 14px;
  }
}
/* Services Section CSS Ends */
