.blog_section{
    padding: 40px 0px;
    background-color: #121212;
    color: #fff;
}
.blog_section_content p {
    margin: 10px 0px;
    line-height: 2;
    text-align: justify;
}
.blog_section_content h1{
    font-size: 45px;
    margin: 20px 0px;
}
.blog_section_content h2{
    font-size: 35px;
    margin: 20px 0px;
}
.blog_section_content p span{
    font-weight: bold;
}
.blog_section_content h3{
    font-size: 30px;
    margin: 20px 0px;
}
/* Video Section CSS */
.video_section{
    background-color: #222a35;
    padding: 30px 0px;
}
.video_section_content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
}
.video1, .video2, .video3, .video4, .video5, .video6, .video7, .video8, .video9, .video10, .video11{
    margin: 10px 0px;
}
.video_content1{
    width: 50vh;
    height: 50vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: transparent;
    padding: 14px 28px;
    border-radius: 3px;
}
/* Video Section CSS Ends */
