/* Faq Section CSS Start */
.faq_section {
    background-color: #1b1212;
    padding: 80px 0px;
  }
  .faq_section_content {
    width: 70%;
    margin: auto;
    text-align: center;
  }
  .faq_section_content h4 {
    display: block;
    line-height: 1;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    margin-bottom: 12px;
    letter-spacing: 1px;
    color: #d81921;
  }
  .faq_section_content h2 {
    font-size: 32px;
    font-weight: 700;
    color: white;
    margin: 0px 0px 50px 0px;
  }
  .faq_question {
    display: block;
    width: 100%;
    margin: 20px auto;
    text-align: left;
    padding: 16px 34px;
    border-radius: 50px;
    background-color: #12151e;
    border: 3px solid #d81921;
    color: #e3dfdf;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
  }
  .faq_question:hover {
    background-color: #d81921;
    color: white;
  }
  .faq_collapse_content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: justify;
    color: #fff;
    padding: 0rem 1.25rem;
    display: none;
  }
  .content_show {
    display: block;
  }
  /* Faq Section Responsive CSS */
  @media (max-width: 480px) {
    .faq_section_content h2,
    .our_clients_section_content h2 {
      font-size: 22px;
    }
    .faq_section_content{
      width: 100% !important;
      margin: 0px;
    }
    .faq_collapse_content {
      font-size: 12px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px){
    .faq_section_content{
      width: 100% !important;
    }
    .faq_collapse_content {
      font-size: 12px;
    }
  }
  /* Faq Section CSS Ends */
  