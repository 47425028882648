.categoryTitle {
  background-color: #222a35;
  padding: 30px 0px;
}
.categoryTitle p {
  font-weight: bold;
}
.categoryTitle p svg {
  color: white;
  font-size: 14px;
  padding-top: 3px;
}
.categoryTitle p span {
  color: red;
}
