.serverError{
    background-color: black;
    color: #fff;
    padding: 15rem 0px;
}
.serverErrorContent h2, .serverErrorContent h4, .serverErrorContent p, .home_btn {
    text-align: center;
    width: 100%;
    margin: auto;
}
.serverErrorContent p{
    margin: 20px auto;
}
.serverErrorContent h2{
    font-size: 15rem;
}
.home_btn button{
    outline: none;
    border: none;
    background-color: grey;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 40px 0px;
    cursor: pointer;
    font-weight: bold;
}
.home_btn button:hover{
    border: 2px solid red;
}
/* Responsive CSS */
@media(max-width: 480px){
    .serverErrorContent h2{
        font-size: 10rem;
    }
}