.whoWeAre_text {
  background-color: #273139;
  color: white;
  padding: 40px 0px 0px 0px;
}
.whoWeAre_text_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.whoWeAre_text_content_p h2 {
  font-weight: 700;
  font-size: 32px;
  color: red;
  margin: 10px 0px;
}
.whoWeAre_text_content_p h2 span {
  color: white;
  font-size: 36px;
}
.whoWeAre_text_content_p p {
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0px;
  text-align: justify;
}
.whoWeAre_text_content_img {
  text-align: center;
}
.whoWeAre_text_content_img img {
  max-width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 50%;
  transition: 0.3s;
}
.whoWeAre_text_content_img img:hover {
  transform: scale(1.1);
}
.padd {
  padding: 0px 0px 0px 0px;
}
.whoWeAre_text_content_img svg {
  font-size: 200px;
}
.mobile {
  display: none;
}
/* Who We Are Text Section Responsive CSS Start*/
@media (max-width: 480px) {
  .whoWeAre_text_content {
    grid-template-columns: 1fr;
  }
  .whoWeAre_text_content_p h2 span {
    font-size: 21px;
  }
  .whoWeAre_text_content_p h2 {
    font-size: 19px;
  }
  .whoWeAre_text_content_p p {
    font-size: 14px;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .whoWeAre_text_content {
    grid-template-columns: 1fr;
    padding: 0px 10px;
  }
  .whoWeAre_text_content_p h2 span {
    font-size: 30px;
  }
  .whoWeAre_text_content_p h2 {
    font-size: 28px;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .whoWeAre_text_content_p h2 {
    font-size: 22px;
  }
  .whoWeAre_text_content_p h2 span {
    font-size: 26px;
  }
  .whoWeAre_text_content_p p {
    font-size: 14px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .whoWeAre_text_content_p h2 {
    font-size: 26px;
  }
  .whoWeAre_text_content_p h2 span {
    font-size: 30px;
  }
}
/* Who We Are Text Section Responsive CSS Ends*/

/* Mission & Vision Section CSS Start */
.mission_vision_section {
  background-color: #1a2e35;
  padding: 40px 0px;
  color: #fff;
  text-align: center;
}
.mission_content h2,
.vision_content h2 {
  color: white;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  padding: 30px 0px;
  margin: 10px 0px;
  position: relative;
}
.mission_content h2::after,
.vision_content h2::after {
  content: " ";
  display: block;
  position: absolute;
  height: 4px;
  width: 90px;
  background: red;
  left: 42%;
  margin-top: 6px;
}
.mv_content_type {
  text-align: center;
}
.mv_content_circle01 {
  background-color: #2c444e;
  border-radius: 50%;
  padding: 4px 2px;
  width: 40%;
  margin: 0px auto;
}
.mv_content_circle02 {
  border: 10px solid #fff;
  border-radius: 50%;
  margin: 15px;
  padding: 40px 30px;
}
.mv_content_circle02 svg {
  font-size: 80px;
  color: white;
}
.mv_content_circle02:hover {
  border: 10px solid red;
}
.colorWhite {
  background-color: #fff !important;
}
.colorWhite:hover {
  background-color: red !important;
}
.p_set {
  width: 80%;
  margin: 0px auto;
}

/* Mission & Vision Section Responsive CSS Start */
@media (max-width: 480px){
  .mv_content_circle02 svg {
    font-size: 40px;
  }
  .mv_content_circle02 {
    padding: 10px 10px;
  }
  .p_set {
    font-size: 12px;
  }
  .mission_content h2, .vision_content h2{
    font-size: 21px;
  }
  .vision_content h2::after, .mission_content h2::after{
    width: 60px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .mv_content_circle02 svg {
    font-size: 50px;
  }
  .mission_content h2, .vision_content h2{
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .mv_content_circle02 svg {
    font-size: 50px;
  }
  .mv_content_circle02 {
    padding: 25px 16px;
  }
  .mission_content h2, .vision_content h2{
    font-size: 26px;
  }
  .vision_content h2::after, .mission_content h2::after{
    width: 80px;
    left: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .mv_content_circle02 {
    padding: 30px 16px;
  }
  .mv_content_circle02 svg {
    font-size: 60px;
  }
}
@media (min-width: 1920px) {
  .mv_content_circle02 {
    padding: 80px 20px;
  }
  .vision_content h2::after,
  .mission_content h2::after {
    left: 45%;
  }
}
/* Mission & Vision Section Responsive CSS Ends */

/* Mission & Vision Section CSS Ends */

/* Core Values Section CSS Start */
.coreValues {
  background-color: #1a2e35;
  padding: 40px 0px;
}
.coreValues_content h2 {
  color: white;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  padding: 30px 0px;
  margin: 10px 0px;
  position: relative;
}
.coreValues_content h2::after {
  content: " ";
  display: block;
  position: absolute;
  height: 4px;
  width: 120px;
  background: red;
  left: 45%;
  margin-top: 6px;
}
.coreValues_content_type {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
}
.coreValues_content_type_detail {
  text-align: center;
}
.coreValues_content_circle1 {
  background-color: #2c444e;
  border-radius: 50%;
  padding: 4px 2px;
}
.coreValues_content_circle2 {
  border: 10px solid #b35efe;
  border-radius: 50%;
  margin: 15px;
  padding: 40px 30px;
}
.coreValues_content_circle2:hover {
  border: 10px solid red;
}
.coreValues_content_circle2 svg {
  font-size: 80px;
  color: white;
}
.down_arrow {
  font-size: 40px;
  color: #ced8dd;
}
.circle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dotted_line {
  padding: 10px 0px;
  margin: 0px 5px;
  font-size: 20px;
  color: #ced8dd;
}
.coreValues_content_circle3 {
  background-color: #2c444e;
  border-radius: 50%;
  width: 40px;
  padding: 10px 8px;
}
.coreValues_content_circle4 {
  background-color: #b35efe;
  border-radius: 50%;
  padding: 5px;
}
.coreValues_content_circle4:hover {
  background-color: red;
}
.value_title {
  color: #ced8dd;
  margin: 10px 0px;
}
.value_context {
  color: #dde4e7;
  margin: 10px 0px;
}
.borderColor7738 {
  border: 10px solid #fd7738;
}
.backgroundColor7738 {
  background-color: #fd7738;
}
.borderColor225 {
  border: 10px solid #a0d225;
}
.backgroundColor225 {
  background-color: #a0d225;
}
.borderColor2fe {
  border: 10px solid #7642fe;
}
.backgroundColor2fe {
  background-color: #7642fe;
}
.borderColorC99 {
  border: 10px solid #fd6c99;
}
.backgroundColorC99 {
  background-color: #fd6c99;
}

/* Core Values Section Responsive CSS Start */

/* Core Values Section Responsive CSS End */
@media (max-width: 480px){
  .coreValues_content_type{
    grid-template-columns: 1fr ;
  }
  .coreValues_content_type_detail{
    width: 40%;
    margin: auto;
  }
  .coreValues_content h2{
    font-size: 21px;
  }
  .coreValues_content h2::after {
    width: 50px;
    left: 43%;
  }
  .coreValues_content_circle2 svg {
    font-size: 40px;
  }
  .para{
    font-size: 12px;
  }
  .coreValues_content_circle2 {
    padding: 10px 0px;
  }
  .value_title h3{
    font-size: 14px;
  }
}
@media (min-width: 481px) and (max-width: 767px){
  .coreValues_content_type{
    grid-template-columns: 1fr ;
  }
  .coreValues_content_type_detail{
    width: 40%;
    margin: auto;
  }
  .coreValues_content h2{
    font-size: 30px;
  }
  .coreValues_content h2::after {
    width: 50px;
    left: 46%;
  }
  .coreValues_content_circle2 svg {
    font-size: 50px;
  }
  .para{
    font-size: 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px){
  .coreValues_content_type{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .coreValues_content_type_detail{
    width: 70%;
    margin: auto;
  }
  .coreValues_content h2::after {
    width: 94px;
  }
  .coreValues_content_circle2 svg {
    font-size: 60px;
  }
  .coreValues_content h2::after {
    left: 44.5%;
  }
  .coreValues_content_circle2 {
    padding: 30px 20px;
  }
  .para{
    font-size: 12px;
  }
  .coreValues_content h2{
    font-size: 24px;
  }
  .coreValues_content h2::after {
    width: 70px;
    left: 46%;
  }
}
@media (min-width: 992px) and (max-width: 1199px){
  .coreValues_content_type{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .coreValues_content_type_detail{
    width: 70%;
    margin: auto;
  }
  .coreValues_content h2::after {
    width: 94px;
  }
  .coreValues_content_circle2 svg {
    font-size: 70px;
  }
  .coreValues_content h2::after {
    left: 45.5%;
  }
  .para{
    font-size: 13px;
  }
}
@media(min-width: 1920px){
  .coreValues_content_circle2 {
    padding: 80px 30px;
  }
  .coreValues_content h2::after {
    left: 46.5%;
  }
}
/* Core Values Section CSS Ends */
