.images_container{
    background-color: #222a35 ;
    padding: 30px 0px;
}
.images_container_content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: center;
}
.image img{
    width: 100%;
    margin: auto;
    cursor: pointer;
}
/* Responsive CSS */
@media (max-width: 480px){
    .images_container_content{
        grid-template-columns: 1fr;
    }
}
@media (min-width: 481px) and (max-width: 767px){
    .images_container_content{
        grid-template-columns: 1fr 1fr;
    }
}