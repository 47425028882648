/* Contact Details Section CSS Start */
.contact_details_section {
  background-color: #2c3740;
  padding: 40px 0px;
}
.contact_details_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.office_location iframe {
  border-radius: 20px;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}
.contact_details {
  background-color: #41515e;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  padding: 20px;
  border-radius: 20px;
}
.contact_details h2 {
  color: white;
  font-weight: bolder;
}
.contact_details h4 {
  color: white;
  margin: 10px 0px 20px 0px;
  font-weight: 500;
}
.address {
  display: flex;
  align-items: center;
  border-top: 2px solid grey;
  gap: 10px;
}
.location_icon {
  background-color: #d81921;
  font-size: 25px;
  margin: 20px 0px;
  border-radius: 50%;
  padding: 10px 10px 5px 10px;
}
.location_icon:hover {
  color: white;
}
.address h4 {
  padding: 10px;
  font-weight: bolder;
  margin: 0px;
}
.address h4 a {
  text-decoration: none;
  color: white;
}
.address h4 a:hover {
  color: #d81921;
}
/* Responsive Contact Details Section CSS Start */
@media (max-width: 480px) {
  .contact_details_content {
    grid-template-columns: 1fr;
  }
  .contact_details h4 {
    font-size: 12px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .contact_details_content {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact_details {
    padding: 10px 20px;
  }
}
@media (min-width: 1920px) {
  .contact_details {
    padding: 30px 20px;
  }
}
/* Contact Details Section CSS Ends */

/* Form Section CSS Start */
.form_section {
  padding: 40px;
  background-color: #2c3740;
}
.form_section_content {
  background-color: #41515e;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  padding: 20px;
  border-radius: 20px;
}
.name_field {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.name_field input{
    width: 97%;
}
.name_field input,
.message textarea {
  background-color: #66737e;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  color: white;
  margin: 10px;
}
.name_field input::placeholder,
.message textarea::placeholder {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}
.react-tel-input .form-control {
  border: none !important;
}
.react-tel-input {
  border: none !important;
  margin: 0px 10px 0px 10px !important;
}
.react-tel-input .selected-flag {
  /*background-color: #66737e !important;*/
  border-radius: 20px 0 0 20px !important;
  padding: 8px 20px !important;
}
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}
.react-tel-input .form-control {
  border-radius: 20px !important;
  padding-left: 55px !important;
}
.message {
  width: 99%;
  height: 200px;
}
.submit_button {
  margin: 20px 0px;
}
.submit_button button {
  padding: 10px 30px;
  margin: 10px;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  font-size: 14px;
  font-weight: bolder;
}
.submit_button button:hover {
  background-color: #d81921;
  color: #fff;
}
.error{
    font-size: 16px;
    color: red;
    margin-left: 13px;
    font-weight: bolder;
}
.submit{
    font-size: 16px;
    color: green;
    margin-left: 13px;
    font-weight: bolder;
}
.borderRed{
  border: 2px solid red;
}
.contactForm_title h2 {
  color: white;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  padding: 30px 0px;
  margin-bottom: 30px;
  position: relative;
}
.contactForm_title h2::after {
  content: " ";
  display: block;
  position: absolute;
  height: 4px;
  width: 120px;
  background: red;
  left: 45%;
  margin-top: 20px;
}
/* Form Section Responsive CSS Start */
@media(max-width: 480px){
    .name_field {
        grid-template-columns: 1fr;
    }
    .form-control{
        margin: 20px;
        width: 96% !important;
    }
    .message textarea, .submit_button button{
        margin: 20px 10px;
    }
    .message{
        width: 97%;
    }
    .form_section {
        padding: 40px 0px 40px 0px;
    }
    .contactForm_title h2{
      font-size: 24px;
    }
    .contactForm_title h2::after {
      left: 30%;
      width: 105px;
    }
}
@media (min-width: 481px) and (max-width: 517px){
    .name_field {
        grid-template-columns: 1fr;
    }
    .form-control{
        margin: 20px;
    }
    .message textarea, .submit_button button{
        margin: 20px 10px;
    }
    .message{
        width: 98%;
    }
    .form_section {
        padding: 40px 0px 40px 0px;
    }
}
@media (min-width: 518px) and (max-width: 767px){
    .name_field {
        grid-template-columns: 1fr;
    }
    .form-control{
        margin: 20px;
    }
    .message textarea, .submit_button button{
        margin: 20px 10px;
    }
    .message{
        width: 98%;
    }
    .form_section {
        padding: 40px 20px 40px 20px;
    }
}
@media (min-width: 768px) and (max-width: 820px){
    .name_field {
        grid-template-columns: 1fr;
    }
    .form-control{
        margin: 20px;
        width: 97.5% !important;
    }
    .message textarea, .submit_button button{
        margin: 20px 10px;
    }
    .form_section {
        padding: 40px 0px 40px 0px;
    }
}
@media (min-width: 481px) and (max-width: 767px){
  .contactForm_title h2::after {
    left: 36%;
  }
  .contactForm_title h2{
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px){
  .contactForm_title h2::after {
    left: 42%;
  }
}
@media (min-width: 992px) and (max-width: 1040px){
  .contactForm_title h2::after {
    left: 43%;
  }
}
@media (min-width: 1041px) and (max-width: 1199px){
  .contactForm_title h2::after {
    left: 44%;
  }
}
@media (min-width: 821px) and (max-width: 1919px){
    .form-control{
        width: 96.5% !important;
    }
}
@media (min-width: 1920px){
    .form-control{
        width: 97.5% !important;
    }
    .message {
        width: 99.3%;
    }
}
/* Form Section Responsive CSS Ends */
/* Form Section CSS Ends */
