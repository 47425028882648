/* Our Clients Section CSS Start */
.our_clients_section {
  background-color: #fff;
  padding: 60px 0px;
}
.our_clients_section_content h2 {
  font-size: 35px;
  font-weight: 700;
  color: black;
  margin-bottom: 3rem;
  text-align: center;
}
.cli_image {
  width: 113px;
  margin: auto;
}
.slick-prev:before, .slick-next:before{
  color: black !important;
}
/* Our Clients Section Responsive CSS */
@media (max-width: 480px) {
  .our_clients_section_content{
    padding: 0px 22px;
  }
  .our_clients_section_content h2 {
    font-size: 18px;
  }
}
@media (min-width: 481px) and (max-width: 518px) {
  .our_clients_section_content{
    padding: 0px 20px;
  }
  .our_clients_section_content h2{
    font-size: 30px;
  }
}
/* Our Clients Section CSS Ends */
